// src/components/TimeSheets.js

import React, { useState, useEffect } from 'react'; // Removed useRef
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Form,
  Spinner,
  InputGroup,
  FormControl,
  Modal,
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { collection, onSnapshot, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';
import * as XLSX from 'xlsx';
import {
  faTrash,
  faFileExcel,
  faFileCsv,
  faFilePdf,
  faUser,
  faCalendarAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast, ToastContainer } from 'react-toastify';
import { generateSummaryPDF } from '../components/timesheets/summaryPDF';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/TimeSheets.css';
import '../styles/Dashboard.css'; // Ensure Dashboard styles are available if needed

const TimeSheets = () => {
  const [timesheets, setTimesheets] = useState([]);
  const [filteredTimesheets, setFilteredTimesheets] = useState([]);
  const [employeeFilter, setEmployeeFilter] = useState('');
  const [dateRange, setDateRange] = useState([null, null]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalHoursWorked, setTotalHoursWorked] = useState('0.00');
  const [totalPay, setTotalPay] = useState('0.00');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [timesheetToDelete, setTimesheetToDelete] = useState(null);

  // New state to track edited hourly rates
  const [editedRates, setEditedRates] = useState({});

  // Fetch timesheets from Firestore
  useEffect(() => {
    const fetchTimesheets = async () => {
      setIsLoading(true);
      const unsubscribe = onSnapshot(
        collection(db, 'timesheets'),
        async (querySnapshot) => {
          const timesheetsData = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          // Calculate totalHours and update Firestore if needed
          const updatedTimesheets = [];
          for (const ts of timesheetsData) {
            const calculatedTotalHours = parseFloat(
              calculateTotalHours(ts.clockIn, ts.clockOut, ts.breakTime || 0)
            );

            if (calculatedTotalHours && ts.totalHours !== calculatedTotalHours) {
              try {
                const docRef = doc(db, 'timesheets', ts.id);
                await updateDoc(docRef, { totalHours: calculatedTotalHours });
                toast.success(`Total hours updated for ${ts.employeeName}.`);
              } catch (error) {
                console.error(`Error updating totalHours for ${ts.id}:`, error);
                toast.error(`Failed to update total hours for ${ts.employeeName}.`);
              }
            }

            updatedTimesheets.push({
              ...ts,
              totalHours: calculatedTotalHours, // Ensure the UI has the updated value
            });
          }

          setTimesheets(updatedTimesheets);
          setIsLoading(false);
        },
        (error) => {
          console.error('Error fetching timesheets:', error);
          setIsLoading(false);
          toast.error('Failed to fetch timesheets. Please try again.');
        }
      );

      return () => unsubscribe();
    };

    fetchTimesheets();
  }, []);

  // Filter timesheets by employee and date range
  useEffect(() => {
    let filtered = timesheets;

    if (employeeFilter) {
      filtered = filtered.filter((ts) =>
        ts.employeeName.toLowerCase().includes(employeeFilter.toLowerCase())
      );
    }

    if (dateRange[0] && dateRange[1]) {
      filtered = filtered.filter((ts) => {
        const logDate = new Date(ts.logDate);
        return logDate >= dateRange[0] && logDate <= dateRange[1];
      });
    }

    filtered.sort((a, b) => new Date(b.logDate) - new Date(a.logDate));

    setFilteredTimesheets(filtered);

    const totalHours = filtered.reduce(
      (sum, ts) =>
        sum +
        parseFloat(
          calculateTotalHours(ts.clockIn, ts.clockOut, ts.breakTime || 0)
        ),
      0
    );

    const totalPay = filtered.reduce(
      (sum, ts) =>
        sum +
        (ts.hourlyRate && ts.clockIn && ts.clockOut
          ? ts.hourlyRate *
            parseFloat(
              calculateTotalHours(ts.clockIn, ts.clockOut, ts.breakTime || 0)
            )
          : 0),
      0
    );

    setTotalHoursWorked(totalHours.toFixed(2));
    setTotalPay(totalPay.toFixed(2));
  }, [timesheets, employeeFilter, dateRange]);

  const clearFilters = () => {
    setEmployeeFilter('');
    setDateRange([null, null]);
  };

  // Updated handleHourlyRateChange to update onBlur instead of onChange
  const handleHourlyRateChange = async (id, rate) => {
    if (rate === '') {
      // If the input is cleared, you might want to handle it differently
      // For now, let's not update Firestore
      return;
    }

    try {
      const parsedRate = parseFloat(rate);
      if (isNaN(parsedRate)) {
        toast.error('Invalid hourly rate.');
        return;
      }

      const docRef = doc(db, 'timesheets', id);
      await updateDoc(docRef, { hourlyRate: parsedRate });
      toast.success('Hourly rate updated successfully!');

      // Optionally, update the local timesheets state to reflect the new rate immediately
      setTimesheets((prevTimesheets) =>
        prevTimesheets.map((ts) =>
          ts.id === id ? { ...ts, hourlyRate: parsedRate } : ts
        )
      );

      // Remove the edited rate from local state after successful update
      setEditedRates((prevEditedRates) => {
        const updatedRates = { ...prevEditedRates };
        delete updatedRates[id];
        return updatedRates;
      });
    } catch (error) {
      console.error('Error updating hourly rate:', error);
      toast.error('Failed to update hourly rate. Please try again.');
    }
  };

  const handleExportExcel = () => {
    const data = filteredTimesheets.map((ts) => ({
      EmployeeName: ts.employeeName,
      Date: ts.logDate,
      TimeIn: ts.clockIn,
      TimeOut: ts.clockOut,
      TotalHours: calculateTotalHours(ts.clockIn, ts.clockOut, ts.breakTime || 0),
      HourlyRate: ts.hourlyRate || 0,
      TotalPay: ts.hourlyRate
        ? (
            ts.hourlyRate *
            calculateTotalHours(ts.clockIn, ts.clockOut, ts.breakTime || 0)
          ).toFixed(2)
        : '0.00',
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Timesheets');
    XLSX.writeFile(workbook, 'timesheets.xlsx');
    toast.success('Exported to Excel successfully!');
  };

  const handleExportCSV = () => {
    const data = filteredTimesheets.map((ts) => ({
      EmployeeName: ts.employeeName,
      Date: ts.logDate,
      TimeIn: ts.clockIn,
      TimeOut: ts.clockOut,
      TotalHours: calculateTotalHours(ts.clockIn, ts.clockOut, ts.breakTime || 0),
      HourlyRate: ts.hourlyRate || 0,
      TotalPay: ts.hourlyRate
        ? (
            ts.hourlyRate *
            calculateTotalHours(ts.clockIn, ts.clockOut, ts.breakTime || 0)
          ).toFixed(2)
        : '0.00',
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const csvData = XLSX.utils.sheet_to_csv(worksheet);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'timesheets.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    toast.success('Exported to CSV successfully!');
  };

  const handleExportPDF = () => {
    const startDate = dateRange[0];
    const endDate = dateRange[1];

    // Ensure both dates are valid or set to 'N/A'
    const formattedStartDate = startDate
      ? startDate.toISOString().split('T')[0]
      : 'N/A';
    const formattedEndDate = endDate
      ? endDate.toISOString().split('T')[0]
      : 'N/A';

    generateSummaryPDF({
      companyInfo: {
        name: 'Imagine Seniorcare Inc.',
        email: 'imaginescinc@gmail.com',
        phone: '(831)-332-5053',
        website: 'imagineseniorcareinc.com',
        address: '255 El Camino Real #204, Burlingame, CA 94010',
      },
      aggregatedData: filteredTimesheets.map((ts) => ({
        employeeName: ts.employeeName,
        totalHours: ts.totalHours || '0.00',
        totalOvertime: ts.overtime || '0.00',
        ratePerHour: ts.hourlyRate || '0.00',
        totalPay: (
          ts.hourlyRate *
          calculateTotalHours(ts.clockIn, ts.clockOut, ts.breakTime)
        ).toFixed(2),
        timesheets: [], // Add timesheets for detailed logs if needed
      })),
      timesheetData: {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      },
      notes: 'Generated PDF summary.',
      overtimeRate: '1.5x',
      includeDetailedLogs: true, // Set to false if no detailed logs are required
    });
    toast.success('PDF generated successfully!');
  };

  const calculateTotalHours = (clockIn, clockOut, breakTime = 0) => {
    if (!clockIn || !clockOut) {
      console.warn('Missing clockIn or clockOut:', { clockIn, clockOut });
      return '0.00'; // Return 0 hours for missing data
    }

    try {
      // Use a reference date to construct valid Date objects
      const referenceDate = '1970-01-01'; // Any reference date
      const clockInTime = new Date(`${referenceDate} ${clockIn}`);
      const clockOutTime = new Date(`${referenceDate} ${clockOut}`);

      // Handle invalid date parsing
      if (isNaN(clockInTime) || isNaN(clockOutTime)) {
        console.error('Invalid time format:', { clockIn, clockOut });
        return '0.00';
      }

      // Handle shifts spanning midnight
      if (clockOutTime <= clockInTime) {
        clockOutTime.setDate(clockOutTime.getDate() + 1); // Move clockOut to the next day
      }

      // Calculate total hours worked
      const totalMilliseconds = clockOutTime - clockInTime;
      const totalHours = totalMilliseconds / (1000 * 60 * 60); // Convert ms to hours
      const breakHours = breakTime / 60; // Convert break time to hours

      return Math.max(totalHours - breakHours, 0).toFixed(2);
    } catch (error) {
      console.error('Error calculating total hours:', error);
      return '0.00';
    }
  };

  // Handle Delete Confirmation Modal
  const handleDelete = (ts) => {
    setTimesheetToDelete(ts);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    if (timesheetToDelete) {
      try {
        await deleteDoc(doc(db, 'timesheets', timesheetToDelete.id));
        toast.success('Timesheet deleted successfully!');
      } catch (error) {
        console.error('Error deleting timesheet:', error);
        toast.error('Failed to delete timesheet. Please try again.');
      } finally {
        setShowDeleteModal(false);
        setTimesheetToDelete(null);
      }
    }
  };

  // Render Filters with Consistent Design
  const renderFilters = () => (
    <Row className="align-items-center mb-4">
      {/* Employee Filter */}
      <Col md={3} className="mb-2 mb-md-0">
        <InputGroup>
          <InputGroup.Text className="rounded-start">
            <FontAwesomeIcon icon={faUser} />
          </InputGroup.Text>
          <Form.Select
            value={employeeFilter}
            onChange={(e) => setEmployeeFilter(e.target.value)}
            className="filter-select rounded-end"
          >
            <option value="">All Employees</option>
            {[...new Set(timesheets.map((ts) => ts.employeeName))].map((name) => (
              <option key={name} value={name}>
                {name}
              </option>
            ))}
          </Form.Select>
        </InputGroup>
      </Col>

      {/* Start Date Filter */}
      <Col md={3} className="mb-2 mb-md-0">
        <InputGroup>
          <InputGroup.Text className="rounded-start">
            <FontAwesomeIcon icon={faCalendarAlt} />
          </InputGroup.Text>
          <DatePicker
            selected={dateRange[0]}
            onChange={(startDate) => setDateRange([startDate, dateRange[1]])}
            selectsStart
            startDate={dateRange[0]}
            endDate={dateRange[1]}
            className="form-control rounded-end"
            placeholderText="Start Date"
          />
        </InputGroup>
      </Col>

      {/* End Date Filter */}
      <Col md={3} className="mb-2 mb-md-0">
        <InputGroup>
          <InputGroup.Text className="rounded-start">
            <FontAwesomeIcon icon={faCalendarAlt} />
          </InputGroup.Text>
          <DatePicker
            selected={dateRange[1]}
            onChange={(endDate) => setDateRange([dateRange[0], endDate])}
            selectsEnd
            startDate={dateRange[0]}
            endDate={dateRange[1]}
            className="form-control rounded-end"
            placeholderText="End Date"
          />
        </InputGroup>
      </Col>

      {/* Clear Filters Button */}
      <Col md={3} className="text-md-end">
        <Button
          variant="secondary"
          onClick={clearFilters}
          className="ms-2 clear-filters-btn rounded"
        >
          Clear Filters
        </Button>
      </Col>
    </Row>
  );

  return (
    <Container fluid className="timecard-container">
      {/* Header Section */}
      <Row className="align-items-center mb-3">
        <Col>
          <h1 className="timecard-title">Timesheets</h1>
        </Col>
        {/* Future Action Buttons Can Be Added Here */}
      </Row>

      <hr className="divider" />

      {/* Filters and Download Section */}
      <div className="filters-and-download">
        {/* Filters Section */}
        {renderFilters()}

        {/* Download Buttons */}
        <Row className="mb-2">
          <Col>
            <div className="download-buttons d-flex align-items-center">
              <Button onClick={handleExportExcel} className="download-btn me-2">
                <FontAwesomeIcon icon={faFileExcel} /> Export to Excel
              </Button>
              <Button onClick={handleExportCSV} className="download-btn me-2">
                <FontAwesomeIcon icon={faFileCsv} /> Export to CSV
              </Button>
              <Button onClick={handleExportPDF} className="download-btn">
                <FontAwesomeIcon icon={faFilePdf} /> Export to PDF
              </Button>
            </div>
          </Col>
        </Row>
      </div>

      {/* Timesheets Table */}
      <Row>
        <Col>
          {isLoading ? (
            <div className="text-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Employee Name</th>
                  <th>Date</th>
                  <th>Time In/Out</th>
                  <th>Total Hours</th>
                  <th>Hourly Wage</th>
                  <th>Total Pay</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredTimesheets.map((ts) => (
                  <tr key={ts.id}>
                    <td>{ts.employeeName || '-'}</td>
                    <td>{ts.logDate ? new Date(ts.logDate).toLocaleDateString() : '-'}</td>
                    <td>{ts.clockIn && ts.clockOut ? `${ts.clockIn} - ${ts.clockOut}` : '-'}</td>
                    <td>{calculateTotalHours(ts.clockIn, ts.clockOut, ts.breakTime || 0)}</td>
                    <td>
                      <InputGroup>
                        <FormControl
                          type="number"
                          value={
                            editedRates[ts.id] !== undefined
                              ? editedRates[ts.id]
                              : ts.hourlyRate || ''
                          }
                          onChange={(e) =>
                            setEditedRates({
                              ...editedRates,
                              [ts.id]: e.target.value,
                            })
                          }
                          onBlur={(e) =>
                            handleHourlyRateChange(ts.id, e.target.value)
                          }
                          placeholder="Rate"
                        />
                      </InputGroup>
                    </td>
                    <td>
                      {ts.hourlyRate
                        ? (
                            ts.hourlyRate *
                            calculateTotalHours(ts.clockIn, ts.clockOut, ts.breakTime || 0)
                          ).toFixed(2)
                        : '0.00'}
                    </td>
                    <td>
                      <Button variant="danger" onClick={() => handleDelete(ts)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="3" style={{ fontWeight: 'bold', textAlign: 'right' }}>
                    Totals:
                  </td>
                  <td>{totalHoursWorked} hrs</td>
                  <td></td>
                  <td>${totalPay}</td>
                  <td></td>
                </tr>
              </tfoot>
            </Table>
          )}
        </Col>
      </Row>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton className="employee-log-modal-header">
          <Modal.Title style={{ fontWeight: '550', letterSpacing: '1px', fontFamily: 'Arial, Helvetica, sans-serif', color: '#333' }}>
            Confirm Delete
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="employee-log-modal-body">
          <p className='mt-2 mb-2'>Are you sure you want to delete <strong>{timesheetToDelete?.employeeName}?</strong></p>
        </Modal.Body>
        <Modal.Footer className="employee-log-modal-footer">
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Delete Timesheet
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Toast Container for Notifications */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastClassName="custom-toast"
        bodyClassName="custom-toast-body"
      />
    </Container>
  );
};

export default TimeSheets;
