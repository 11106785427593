// AdminDashboard.jsx
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify'; // Importing ToastContainer
import Sidebar from './Sidebar'; // Sidebar for navigation
import Dashboard from './Dashboard'; // Dashboard content
import EmployeeTimecard from './EmployeeTimecard';
import TimeSheets from './TimeSheets';
import Applications from './Applications';
import Contracts from './Contracts';
import Invoices from './Invoice';
import CreateInvoice from './CreateInvoice';
import Profile from './logout'; // Import the Profile component
import RateSheet from './RateSheet';
import 'react-toastify/dist/ReactToastify.css'; // Toast CSS

const AdminDashboard = () => {
  const location = useLocation(); // Handle page location

  return (
    <Container fluid>
      <Row>
        {/* Sidebar */}
        <Col md={2} className="p-0">
          <Sidebar />
        </Col>

        {/* Dynamic content switching based on routes */}
        <Col md={10} className="p-0">
          <Routes location={location}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="employee-timecard" element={<EmployeeTimecard />} />
            <Route path="timesheets" element={<TimeSheets />} />
            <Route path="applications" element={<Applications />} />
            <Route path="contracts" element={<Contracts />} />
            <Route path="invoices" element={<Invoices />} />
            <Route path="create-invoice" element={<CreateInvoice />} />
            <Route path="profile" element={<Profile />} /> {/* Updated Route */}
            <Route path="rate-sheet" element={<RateSheet />} />
            {/* Default route */}
            <Route path="/" element={<Navigate to="/admin/dashboard" />} />
          </Routes>
        </Col>
      </Row>
      {/* Toast Container for Notifications */}
      <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar={false} />
    </Container>
  );
};

export default AdminDashboard;
