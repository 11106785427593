import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import logo from '../../images/logo.webp'; // Adjust the path as necessary

const generateSummaryPDF = async ({
  companyInfo,
  aggregatedData,
  timesheetData,
  notes,
  overtimeRate,
  includeDetailedLogs,
}) => {
  const doc = new jsPDF();
  const primaryColor = '#D77124';
  const margin = 20;

  // Add logo and company information
  doc.addImage(logo, 'WEBP', margin, 10, 50, 25);
  doc.setFontSize(16);
  doc.setTextColor(primaryColor);
  doc.text(companyInfo.name, margin + 60, 15);
  doc.setFontSize(11);
  doc.setTextColor(0, 0, 0);
  doc.text(`Email: ${companyInfo.email} | Phone: ${companyInfo.phone}`, margin + 60, 22);
  doc.text(`Website: ${companyInfo.website}`, margin + 60, 29);
  doc.text(companyInfo.address, margin + 60, 36);

  // Divider line
  doc.setDrawColor(primaryColor);
  doc.setLineWidth(0.5);
  doc.line(margin, 42, doc.internal.pageSize.width - margin, 42);

  // Report Title and Details
  doc.setFontSize(20);
  doc.setTextColor(primaryColor);
  doc.text('Employee Payment Summary', margin, 55);

  // Pay Period Details and Notes
  doc.setFontSize(12);
  doc.setTextColor(0, 0, 0);
  doc.text(`Pay Period: ${timesheetData.startDate || 'N/A'} - ${timesheetData.endDate || 'N/A'}`, margin, 65);
  if (notes) {
    const splitNotes = doc.splitTextToSize(`Notes: ${notes}`, 170);
    doc.text(splitNotes, margin, 73);
  }

  // Summary Table
  autoTable(doc, {
    startY: 85,
    head: [
      [
        'Employee Name',
        'Total Hours Worked',
        `Overtime (${overtimeRate}) (hrs)`,
        'Hourly Wage ($)',
        'Total Pay ($)',
      ],
    ],
    body: aggregatedData.map((emp) => [
      emp.employeeName || 'N/A',
      `${Number(emp.totalHours || 0).toFixed(2)} hrs`,
      `${Number(emp.totalOvertime || 0).toFixed(2)} hrs`,
      `$${Number(emp.ratePerHour || 0).toFixed(2)}`,
      `$${Number(emp.totalPay || 0).toFixed(2)}`,
    ]),
    theme: 'grid',
    headStyles: { fillColor: primaryColor, textColor: [255, 255, 255] },
    styles: { fontSize: 11, cellPadding: 5, textColor: 0 },
  });

  let finalY = doc.lastAutoTable.finalY + 10;

  // Display Detailed Logs if Requested
  if (includeDetailedLogs) {
    aggregatedData.forEach((emp) => {
      if (!emp.timesheets || emp.timesheets.length === 0) return; // Skip if no timesheets

      doc.addPage();
      doc.setFontSize(16);
      doc.setTextColor(primaryColor);
      doc.text(`Detailed Logs for ${emp.employeeName || 'N/A'}`, margin, 20);

      autoTable(doc, {
        startY: 30,
        head: [['Date', 'Clock In', 'Clock Out', 'Break Time', 'Overtime', 'Total Hours']],
        body: emp.timesheets.map((log) => [
          log.date || 'N/A',
          log.clockIn || 'N/A',
          log.clockOut || 'N/A',
          `${Number(log.breakTime || 0)} min`,
          `${Number(log.overtime || 0).toFixed(2)} hrs`,
          `${Number(log.hoursWorked || 0).toFixed(2)} hrs`,
        ]),
        theme: 'grid',
        headStyles: { fillColor: primaryColor, textColor: [255, 255, 255] },
        styles: { fontSize: 11, cellPadding: 5, textColor: 0 },
      });
    });
  }

  // Grand Total Summary at the End
  const grandTotalHours = aggregatedData.reduce((sum, emp) => sum + Number(emp.totalHours || 0), 0).toFixed(2);
  const grandTotalPay = aggregatedData.reduce((sum, emp) => sum + Number(emp.totalPay || 0), 0).toFixed(2);

  doc.setFontSize(14);
  doc.setTextColor(0, 0, 0);
  doc.setFont('helvetica', 'bold');
  doc.text('Total Hours:', margin, finalY);
  doc.text('Total Pay:', margin, finalY + 8);

  doc.setFont('helvetica', 'normal');
  doc.text(`${grandTotalHours} hrs`, margin + 50, finalY);
  doc.text(`$${grandTotalPay}`, margin + 50, finalY + 8);

  // Save the PDF
  doc.save('employee-summary.pdf');
};

export { generateSummaryPDF };
