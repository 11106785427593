import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import logo from '../images/logo.webp'; // Ensure correct path to logo

const generatePDF = async (invoice) => {
  const doc = new jsPDF();
  const orangeColor = '#D77124';
  const margin = 20; // Define margin for spacing

  // Load logo image and set dimensions
  const logoWidth = 50;
  const logoHeight = 25;

  // Header Section with Logo and Company Info
  doc.addImage(logo, 'WEBP', margin, 10, logoWidth, logoHeight);
  doc.setFontSize(16);
  doc.setTextColor(orangeColor);
  doc.text('Imagine Seniorcare Inc.', margin + 60, 15); // Position next to logo
  doc.setFontSize(11);
  doc.setTextColor(0, 0, 0);
  doc.text('Email: imaginescinc@gmail.com | Phone: (831)-332-5053', margin + 60, 22);
  doc.text('Website: imagineseniorcareinc.com', margin + 60, 29);
  doc.text('255 El Camino Real #204, Burlingame, CA 94010', margin + 60, 36);

  // Divider line for visual separation
  doc.setDrawColor(orangeColor);
  doc.setLineWidth(0.5);
  doc.line(margin, 42, doc.internal.pageSize.width - margin, 42);

  // Invoice Title and Main Details Section
  doc.setFontSize(20);
  doc.setTextColor(orangeColor);
  doc.text('Invoice', margin, 55);

  // Invoice Details
  doc.setFontSize(12);
  doc.setTextColor(0, 0, 0);
  const invoiceDetailsTop = 65;
  doc.text(`Invoice Number: ${invoice.invoiceNumber}`, margin, invoiceDetailsTop);
  doc.text(`Date: ${new Date(invoice.createdAt).toLocaleDateString()}`, margin, invoiceDetailsTop + 8);
  doc.text(`Client Name: ${invoice.clientName}`, margin, invoiceDetailsTop + 16);
  doc.text(`Client Address: ${invoice.clientAddress || 'N/A'}`, margin, invoiceDetailsTop + 24);
  doc.text(`Service Period: ${invoice.stayDuration.start ? new Date(invoice.stayDuration.start).toLocaleDateString() : 'N/A'} - ${invoice.stayDuration.end ? new Date(invoice.stayDuration.end).toLocaleDateString() : 'N/A'}`, margin, invoiceDetailsTop + 32);

  // Calculate starting X position to center the table
  const pageWidth = doc.internal.pageSize.width;
  const tableWidth = 180; // Approximate table width based on column widths
  const startX = (pageWidth - tableWidth) / 2;

  // Service Table Section
  const tableTop = invoiceDetailsTop + 50;
  autoTable(doc, {
    startY: tableTop,
    margin: { left: startX }, // Center the table horizontally
    head: [['Item/Service', 'Description', 'Total Time', 'x', 'Rate', 'Price']],
    body: [
      [
        'Seniorcare',
        invoice.description || 'Services Rendered',
        invoice.totalHours,
        'x',
        `$${invoice.hourlyRate.toFixed(2)}`,
        `$${invoice.invoiceAmount.toFixed(2)}`
      ],
      // Add Total Row with bold and larger font style
      [{ content: 'Total', colSpan: 5, styles: { halign: 'right', fontStyle: 'bold', fontSize: 13 } }, { content: `$${invoice.invoiceAmount.toFixed(2)}`, styles: { fontStyle: 'bold', fontSize: 13 } }]
    ],
    theme: 'grid',
    headStyles: { fillColor: orangeColor, textColor: [255, 255, 255] }, // White text for header
    styles: { halign: 'center', fontSize: 11, cellPadding: 5, textColor: 0 }, // Black text for table body
    columnStyles: {
      0: { halign: 'left', cellWidth: 40 },   // Item/Service
      1: { halign: 'left', cellWidth: 55 },   // Description
      2: { halign: 'center', cellWidth: 25 }, // Total Hours
      3: { halign: 'center', cellWidth: 15 }, // x symbol
      4: { halign: 'right', cellWidth: 25 },  // Hourly Rate
      5: { halign: 'right', cellWidth: 30 },  // Price
    }
  });

  // Terms & Conditions Section
  const termsTop = doc.lastAutoTable.finalY + 20;
  doc.setFontSize(16);
  doc.setTextColor(orangeColor);
  doc.text('Terms & Conditions', margin, termsTop);
  doc.setFontSize(11);
  doc.setTextColor(0, 0, 0);
  doc.text('• Payment for the invoice must be made upon receiving this invoice.', margin, termsTop + 8);
  doc.text('• Please make all checks payable to Imagine Seniorcare Incorporated.', margin, termsTop + 14);

  // Save the PDF with the client's name
  doc.save(`Invoice_${invoice.clientName}.pdf`);
};

export default generatePDF;
